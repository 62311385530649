<template>
    <div>

        <div class="hb-primary hb-design-system-border-radius px-4 white--text">
            <hb-header :divider="false">
                <hb-page-header
                    title="Accordions"
                    description="import { HbExpansionPanel } from 'hummingbird-aviary';"
                >
                </hb-page-header>
            </hb-header>
        </div>

        <hb-tabs v-model="activeTab">
            <v-tab
                :ripple="false"
            >
                Prototype
            </v-tab>
            <v-tab
                :ripple="false"
            >
                Examples
            </v-tab>

        </hb-tabs>

        <v-divider></v-divider>

        <div v-if="activeTab === 0" class="pb-4">

            <v-row no-gutters>
                <v-col cols="6" class="pr-6">
                    <v-expansion-panels v-model="accordionGroup" multiple>

                        <hb-expansion-panel
                            :error="error"
                            :caution="caution"
                            :success="success"
                            :guidance="guidance"
                            :disabled="disabled"
                            :expand-icon-off="expandIconOff"
                            :title-icon="titleIcon"
                            :title-icon-color="titleIconColor"
                            :left-cols="leftCols"
                            :right-cols="rightCols"
                            :top-margin="topMargin"
                            :content-padding="contentPadding"
                        >
                            <template v-slot:title>
                                <div v-html="title"></div>
                            </template>
                            <template v-slot:actions>
                                <div v-html="actions"></div>
                            </template>
                            <template v-slot:content>
                                <span v-if="content.length > 0">
                                    <div v-html="content"></div>
                                </span>
                                <span v-else>
                                    <hb-form label="Label">
                                        <HbTextField placeholder="Enter Choice" />
                                    </hb-form>
                                    <hb-form label="Label">
                                        <hb-radio-group row>
                                            <hb-radio label="Yes" value="1"></hb-radio>
                                            <hb-radio label="No" value="-1"></hb-radio>
                                        </hb-radio-group>
                                    </hb-form>
                                </span>
                            </template>
                            <template v-slot:footer>
                                <span v-if="footer.length > 0">
                                    <div v-html="footer"></div>
                                </span>
                                <hb-bottom-action-bar :top-border="false" v-else>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel>
                            <template v-slot:title>
                                Another Accordion 1
                            </template>
                            <template v-slot:content>
                                <hb-form label="Label">
                                    <HbTextField placeholder="Enter Choice" />
                                </hb-form>
                                <hb-form label="Label">
                                    <hb-radio-group row>
                                        <hb-radio label="Yes" value="1"></hb-radio>
                                        <hb-radio label="No" value="-1"></hb-radio>
                                    </hb-radio-group>
                                </hb-form>
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar :top-border="false">
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel>
                            <template v-slot:title>
                                Another Accordion 2
                            </template>
                            <template v-slot:content>
                                <hb-form label="Label">
                                    <HbTextField placeholder="Enter Choice" />
                                </hb-form>
                                <hb-form label="Label">
                                    <hb-radio-group row>
                                        <hb-radio label="Yes" value="1"></hb-radio>
                                        <hb-radio label="No" value="-1"></hb-radio>
                                    </hb-radio-group>
                                </hb-form>
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar :top-border="false">
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                    </v-expansion-panels>

                    <hb-card color="#E0F5F5" title="Code + Data" class="mt-3">
                        <pre class="px-4" :style="codeExpanded1 ? 'height:auto' : 'height:479px;'">
                            <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded1 = !codeExpanded1">
                                {{ codeExpanded1 ? 'Collapse Code' : 'Expand Code' }}
                                <hb-icon>mdi-chevron-down</hb-icon>
                            </div>
&lt;v-expansion-panels v-model="accordionGroup" multiple>
    &lt;hb-expansion-panel<span v-if="error || caution || success || guidance"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ error ? 'error' : caution ? 'caution' : success ? 'success' : guidance ? 'guidance' : '' }}</span><span v-if="disabled"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;disabled</span><span v-if="expandIconOff"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;expand-icon-off</span><span v-if="titleIcon"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:title-icon="{{ titleIcon }}"</span><span v-if="titleIconColor"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:title-icon-color="{{ titleIconColor }}"</span><span v-if="leftCols !== 9"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:left-cols="{{ leftCols }}"</span><span v-if="rightCols !== 3"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:right-cols="{{ rightCols }}"</span><span v-if="!topMargin"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:top-margin="false"</span><span v-if="contentPadding"><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;content-padding"</span><span v-if="error || caution || success || guidance || disabled || expandIconOff || titleIcon || titleIconColor || leftCols !== 9 || rightCols !== 3 || !topMargin || contentPadding"><br />&nbsp;&nbsp;&nbsp;&nbsp;></span><span v-else>></span>
        &lt;template v-slot:title>
            {{ title }}
        &lt;/template>
        &lt;template v-slot:actions>
            {{ actions }}
        &lt;/template>
        &lt;template v-slot:content>
            {{ content.length > 0 ? content : '&lt;hb-form label="Label">&lt;HbTextField placeholder="Enter Choice" />&lt;/hb-form>&lt;hb-form label="Label">&lt;hb-radio-group row>&lt;hb-radio label="Yes" value="1">&lt;/hb-radio>&lt;hb-radio label="No" value="-1">&lt;/hb-radio>&lt;/hb-radio-group>&lt;/hb-form>' }}
        &lt;/template>
        &lt;template v-slot:footer>
            {{ footer.length > 0 ? footer : '&lt;hb-bottom-action-bar :top-border="false">&lt;template v-slot:right-actions>&lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>&lt;/template>&lt;/hb-bottom-action-bar>' }}
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel>
        &lt;template v-slot:title>
            Another Accordion 1
        &lt;/template>
        &lt;template v-slot:content>
            &lt;hb-form label="Label">
                &lt;HbTextField placeholder="Enter Choice" />
            &lt;/hb-form>
            &lt;hb-form label="Label">
                &lt;hb-radio-group row>
                    &lt;hb-radio label="Yes" value="1">&lt;/hb-radio>
                    &lt;hb-radio label="No" value="-1">&lt;/hb-radio>
                &lt;/hb-radio-group>
            &lt;/hb-form>
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar :top-border="false">
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel>
        &lt;template v-slot:title>
            Another Accordion 2
        &lt;/template>
        &lt;template v-slot:content>
            &lt;hb-form label="Label">
                &lt;HbTextField placeholder="Enter Choice" />
            &lt;/hb-form>
            &lt;hb-form label="Label">
                &lt;hb-radio-group row>
                    &lt;hb-radio label="Yes" value="1">&lt;/hb-radio>
                    &lt;hb-radio label="No" value="-1">&lt;/hb-radio>
                &lt;/hb-radio-group>
            &lt;/hb-form>
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar :top-border="false">
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>
&lt;/v-expansion-panels>
                        </pre>
                    </hb-card>
                
                </v-col>

                <v-col cols="3" class="pr-6 pt-3">
                    <hb-card title="Props">

                        <hb-form small label="v-model">
                            {{ accordionGroup }}
                        </hb-form>

                        <hb-form small label="error">
                            <HbSwitch
                                :disabled="caution || success || guidance"
                                v-model="error"
                                :label="error ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="caution">
                            <HbSwitch
                                :disabled="error || success || guidance"
                                v-model="caution"
                                :label="caution ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="success">
                            <HbSwitch
                                :disabled="error || caution || guidance"
                                v-model="success"
                                :label="success ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="guidance">
                            <HbSwitch
                                :disabled="error || caution || success"
                                v-model="guidance"
                                :label="guidance ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="disabled">
                            <HbSwitch
                                v-model="disabled"
                                :label="disabled ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="expand-icon-off">
                            <HbSwitch
                                v-model="expandIconOff"
                                :label="expandIconOff ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="title-icon">
                            <HbTextField
                                v-model="titleIcon"
                                placeholder="Enter 'mdi-code'"
                            />
                        </hb-form>

                        <hb-form small label="title-icon-color">
                            <HbTextField
                                v-model="titleIconColor"
                                placeholder="Enter Color"
                            />
                        </hb-form>

                        <hb-form small label="left-cols">
                            <HbAutocomplete
                                v-model="leftCols"
                                :items="colsArray"
                                placeholder="Select Left Cols"
                                :clearable="false"
                            />
                        </hb-form>

                        <hb-form small label="right-cols">
                            <HbAutocomplete
                                v-model="rightCols"
                                :items="colsArray"
                                placeholder="Select Right Cols"
                                :clearable="false"
                            />
                        </hb-form>

                        <hb-form small label="top-margin">
                            <HbSwitch
                                v-model="topMargin"
                                :label="topMargin ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form small label="content-padding">
                            <HbSwitch
                                v-model="contentPadding"
                                :label="contentPadding ? 'true' : 'false/null'"
                            />
                        </hb-form>

                    </hb-card>
                </v-col>
                
                <v-col cols="3" class="pt-3">

                    <hb-card title="Slots">
                        <hb-form small label="title">
                            <HbTextField
                                v-model="title"
                                placeholder="Enter Title"
                            />
                        </hb-form>

                        <hb-form small label="actions" full>
                            <HbTextarea
                                v-model="actions"
                                :rows="3"
                                placeholder='Enter Html Actions'
                            />
                        </hb-form>

                        <hb-form small label="content" full>
                            <HbTextarea
                                v-model="content"
                                :rows="10"
                                placeholder='<hb-form label="Label"><HbTextField placeholder="Enter Choice" /></hb-form><hb-form label="Label"><hb-radio-group row><hb-radio label="Yes" value="1"></hb-radio><hb-radio label="No" value="-1"></hb-radio></hb-radio-group></hb-form>'
                            />
                        </hb-form>

                        <hb-form small label="footer" full>
                            <HbTextarea
                                v-model="footer"
                                :rows="8"
                                placeholder='<hb-bottom-action-bar :top-border="false"><template v-slot:right-actions><hb-btn @click="doSomething()">Click</hb-btn></template></hb-bottom-action-bar>'
                            />
                        </hb-form>
                    </hb-card>
                </v-col>
            </v-row>
        </div>

        <div v-if="activeTab === 1">

            <hb-card title="Accordion Examples + Code" class="mt-4 mb-6">
                <div class="mt-2 mx-6 pb-6">

                    <v-expansion-panels v-model="panel" multiple>

                        <hb-expansion-panel>
                            <template v-slot:title>
                                Closed by Default
                            </template>
                            <template v-slot:content>
                                <hb-form label="Label">
                                    <HbTextField placeholder="Enter Choice" />
                                </hb-form>
                                <hb-form label="Label">
                                    <hb-radio-group row>
                                        <hb-radio label="Yes" value="1"></hb-radio>
                                        <hb-radio label="No" value="-1"></hb-radio>
                                    </hb-radio-group>
                                </hb-form>
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar :top-border="false">
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel expand-icon-off disabled>
                            <template v-slot:title>
                                Open by Default &amp; Expand Icon Turned Off
                            </template>
                            <template v-slot:content>
                                <hb-form label="Label">
                                    <HbTextField placeholder="Enter Choice" />
                                </hb-form>
                                <hb-form label="Label">
                                    <HbSelect
                                        :items="[ 'Harry', 'Ron' ]"
                                        placeholder="Select Wizard"         
                                    />
                                    <hb-radio-group row>
                                        <hb-radio label="Yes" value="1"></hb-radio>
                                        <hb-radio label="No" value="-1"></hb-radio>
                                    </hb-radio-group>
                                    <HbCheckbox label="Wizards" />
                                    <HbCheckbox label="Wizards" />
                                    <HbCheckbox label="Wizards" />
                                    <HbCheckbox label="Wizards" />
                                </hb-form>
                                <hb-form label="Label">
                                    <hb-radio-group row>
                                        <hb-radio label="Yes" value="1"></hb-radio>
                                        <hb-radio label="No" value="-1"></hb-radio>
                                    </hb-radio-group>
                                </hb-form>
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar :top-border="false">
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel content-padding>
                            <template v-slot:title>
                                Closed by Default with Extra Action Example
                            </template>
                            <template v-slot:actions>
                                <hb-switch v-model="switch1" right>
                                    <template v-slot:label>
                                        {{ switch1 ? 'Yes' : 'No' }}
                                    </template>
                                </hb-switch>
                            </template>
                            <template v-slot:content>
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox label="Wizards" />
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel error content-padding>
                            <template v-slot:title>
                                Error Example
                            </template>
                            <template v-slot:content>
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox label="Wizards" />
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel caution content-padding>
                            <template v-slot:title>
                                Caution Example
                            </template>
                            <template v-slot:content>
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox label="Wizards" />
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel success content-padding>
                            <template v-slot:title>
                                Success Example
                            </template>
                            <template v-slot:content>
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox label="Wizards" />
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>

                        <hb-expansion-panel guidance content-padding>
                            <template v-slot:title>
                                Guidance Example
                            </template>
                            <template v-slot:content>
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox
                                    label="Wizards" 
                                    class="mb-2"
                                />
                                <HbCheckbox label="Wizards" />
                            </template>
                            <template v-slot:footer>
                                <hb-bottom-action-bar>
                                    <template v-slot:right-actions>
                                        <hb-btn @click="doSomething()">Click</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
    <pre>
&lt;v-expansion-panels v-model="panel" multiple>

    &lt;hb-expansion-panel>
        &lt;template v-slot:title>
            Closed by Default
        &lt;/template>
        &lt;template v-slot:content>
            &lt;hb-form label="Label">
                &lt;HbTextField placeholder="Enter Choice" />
            &lt;/hb-form>
            &lt;hb-form label="Label">
                &lt;v-radio-group row hide-details>
                    &lt;hb-radio label="Yes" value="1">&lt;/hb-radio>
                    &lt;hb-radio label="No" value="-1">&lt;/hb-radio>
                &lt;/v-radio-group>
            &lt;/hb-form>
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar :top-border="false">
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel expand-icon-off disabled>
        &lt;template v-slot:title>
            Open by Default &amp; Expand Icon Turned Off
        &lt;/template>
        &lt;template v-slot:content>
            &lt;hb-form label="Label">
                &lt;HbTextField placeholder="Enter Choice" />
            &lt;/hb-form>
            &lt;hb-form label="Label">
                &lt;HbSelect
                    :items="[ 'Harry', 'Ron' ]"
                    placeholder="Select Wizard"         
                />
                &lt;v-radio-group row hide-details class="my-3">
                    &lt;hb-radio label="Yes" value="1">&lt;/hb-radio>
                    &lt;hb-radio label="No" value="-1">&lt;/hb-radio>
                &lt;/v-radio-group>
                &lt;HbCheckbox label="Wizards" />
                &lt;HbCheckbox label="Wizards" />
                &lt;HbCheckbox label="Wizards" />
                &lt;HbCheckbox label="Wizards" />
            &lt;/hb-form>
            &lt;hb-form label="Label">
                &lt;v-radio-group row hide-details>
                    &lt;hb-radio label="Yes" value="1">&lt;/hb-radio>
                    &lt;hb-radio label="No" value="-1">&lt;/hb-radio>
                &lt;/v-radio-group>
            &lt;/hb-form>
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar :top-border="false">
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel content-padding>
        &lt;template v-slot:title>
            Closed by Default with Extra Action Example
        &lt;/template>
        &lt;template v-slot:actions>
            &lt;hb-switch v-model="switch1" right>
                &lt;template v-slot:label>
                    {{ switch1 ? 'Yes' : 'No' }}
                &lt;/template>
            &lt;/hb-switch>
        &lt;/template>
        &lt;template v-slot:content>
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox label="Wizards" />
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar>
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel error content-padding>
        &lt;template v-slot:title>
            Error Example
        &lt;/template>
        &lt;template v-slot:content>
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox label="Wizards" />
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar>
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel caution content-padding>
        &lt;template v-slot:title>
            Caution Example
        &lt;/template>
        &lt;template v-slot:content>
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox label="Wizards" />
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar>
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel success content-padding>
        &lt;template v-slot:title>
            Success Example
        &lt;/template>
        &lt;template v-slot:content>
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox label="Wizards" />
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar>
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>

    &lt;hb-expansion-panel guidance content-padding>
        &lt;template v-slot:title>
            Guidance Example
        &lt;/template>
        &lt;template v-slot:content>
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox
                label="Wizards" 
                class="mb-2"
            />
            &lt;HbCheckbox label="Wizards" />
        &lt;/template>
        &lt;template v-slot:footer>
            &lt;hb-bottom-action-bar>
                &lt;template v-slot:right-actions>
                    &lt;hb-btn @click="doSomething()">Click&lt;/hb-btn>
                &lt;/template>
            &lt;/hb-bottom-action-bar>
        &lt;/template>
    &lt;/hb-expansion-panel>
&lt;/v-expansion-panels>

*note in &lt;script> data:
    panel: [1, 3], // sets first and fourth panel as open by default, change to [0, 1, 2] to set first, second, third as open by default, etc...
    </pre>
                </hb-card>
            </hb-card>

        </div>

        <hb-card class="my-6 pb-1" title="Props">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank" v-if="item.resource.length">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <!--
        <div v-if="activeTab === 2" style="height:5000px">
            <iframe class="mt-4" width="100%" height="100%" style="border:none;" src="https://wells-demo.squarespace.com/read-me-wells"></iframe>
        </div>
        -->
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemAccordions",
        data: function() {
            return {
                activeTab: '',
                checkon1: true,
                checkon2: true,
                checkon3: true,
                checkon4: true,
                panel: [1, 3], // sets first and fourth panel as open by default, change to [0, 1, 2] to set first, second, third as open by default, etc...
                switch1: false,
                accordionGroup: [],
                error: false,
                caution: false,
                success: false,
                guidance: false,
                disabled: false,
                titleIcon: '',
                titleIconColor: '',
                topMargin: true,
                expandIconOff: false,
                contentPadding: false,
                leftCols: 9,
                rightCols: 3,
                colsArray: [1,2,3,4,5,6,7,8,9,10,11],
                title: 'Prototype Accordion Title',
                actions: '',
                content: '',
                footer: '',
                showPanel2: true,
                showPanel3: true,
                codeExpanded1: false,
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type', width: '120' },
                    { text: 'Default', value: 'default', width: '120' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                propItems: [
                    { name: 'error', type: 'boolean', default: 'false', description: 'If changed to true, this applies the error styling to the panel & adds an error icon to the title.', resource: '' },
                    { name: 'caution', type: 'boolean', default: 'false', description: 'If changed to true, this applies the caution styling to the panel & adds a caution icon to the title.', resource: '' },
                    { name: 'success', type: 'boolean', default: 'false', description: 'If changed to true, this applies the success styling to the panel & adds a success icon to the title.', resource: '' },
                    { name: 'guidance', type: 'boolean', default: 'false', description: 'If changed to true, this applies the guidance styling to the panel & adds a guidance icon to the title.', resource: '' },
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If changed to true, this disables the ability to toggle the accordion expanding or collapsing when clicking on the accordion header area.', resource: '' },
                    { name: 'expand-icon-off', type: 'boolean', default: 'false', description: 'If changed to true, this disables the default expand icon on the right side.', resource: '' },
                    { name: 'title-icon', type: 'string', default: 'undefined', description: 'Set an mdi-code icon ( "mdi-xxxx" ) to manually set or override the icon displayed.', resource: '' },
                    { name: 'title-icon-color', type: 'string', default: 'undefined', description: 'Set a color to manually set or override the icon color.', resource: '' },
                    { name: 'left-cols', type: 'string', default: '9', description: 'Change this value to override the default left column width (i.e. the title area) of the panel header. Be sure that the "left-cols" and "right-cols" add up to 12.', resource: '' },
                    { name: 'right-cols', type: 'string', default: '3', description: 'Change this value to override the default right column width (i.e. the actions area) of the panel header. Be sure that the "left-cols" and "right-cols" add up to 12.', resource: '' },
                    { name: 'top-margin', type: 'boolean', default: 'true', description: 'If changed to false, this disables the default 12px top margin between panels.', resource: '' },
                    { name: 'content-padding', type: 'boolean', default: 'false', description: 'If changed to true, this applies "pa-4" (16px of padding on all sides) to the content area.', resource: '' },
                    { name: 'hide-actions-when-closed', type: 'boolean', default: 'false', description: 'If changed to true, this hides anything set in the "actions" slot when the accordion is closed.', resource: '' },
                    { name: 'all vuetify v-expansion-panel props', type: '', default: '', description: 'All vuetify "v-expansion-panel" props (which is a subset of "v-expansion-panels") can be used in an "hb-expansion-panel". See the vuetify documentation link in the next column for the list of available props.', resource: 'https://v2.vuetifyjs.com/en/api/v-expansion-panels/' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' }
                ],
                slotItems: [
                    { name: 'title', description: 'Slot for left column of accordion header, where the title content usually goes.' },
                    { name: 'actions', description: 'Slot for right column of accordion header. You usually should not use this slot unless the design specifically calls for custom content in this area. If you need to make an HbBtn or HbLink not initiate the open/close functionality of the accordion, then do "@click.native.stop=..." for the click events for those components.' },
                    { name: 'content', description: 'Slot for where the accordion content goes that shows up when the accordion is expanded.' },
                    { name: 'footer', description: 'Slot for where the accordion footer goes that shows up when the accordion is expanded. Usually, the only thing that you would place here is an HbBottomActionBar.' }
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when accordion header is clicked.' }
                ]
            };
        },
        watch:{
            panel(){
                if(this.panel.includes(2)){
                    this.switch1 = true;
                } else {
                    this.switch1 = false;
                }
            },
            leftCols(){
                this.rightCols = 12 - this.leftCols;
            },
            rightCols(){
                this.leftCols = 12 - this.rightCols;
            }
        }
    }
</script>

<style scoped>

.hb-expansion-panel >>> .v-input label {
  margin-top: 0 !important;
}

</style>